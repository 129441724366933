import React from 'react'
import Layout from '../../../components/Layout'

const annualBusinessMeetingMinutes = [
  {
    label: '1987, September 23',
    filename: '1987Sep23-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1988, April 21',
    filename: '1988Apr21-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1989, April 06',
    filename: '1989Apr06-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1990, April 26',
    filename: '1990Apr26-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1991, April 24',
    filename: '1991Apr24-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1992, April 24',
    filename: '1992Apr24-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1993, April 09',
    filename: '1993Apr09-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1994, April 05',
    filename: '1994Apr05-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1995, April 21',
    filename: '1995Apr21-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1996, April 19',
    filename: '1996Apr19-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1997, April 11',
    filename: '1997Apr11-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1998, April 15',
    filename: '1998Apr15-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '1999, April 16',
    filename: '1999Apr16-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2000, April 14',
    filename: '2000Apr14-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2001, April 06',
    filename: '2001Apr06-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2002, April 12',
    filename: '2002Apr12-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2003, April 04',
    filename: '2003Apr04-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2004, April 30',
    filename: '2004Apr30-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2005, April 08',
    filename: '2005Apr08-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2006, April 07',
    filename: '2006Apr07-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2007, April 13',
    filename: '2007Apr13-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2008, April 04',
    filename: '2008Apr04-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2009, March 31',
    filename: '2009Mar31-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2010, April 20',
    filename: '2010Apr20-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2011, April 05',
    filename: '2011Apr05-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2012, April 13',
    filename: '2012Apr13-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2013, March 22',
    filename: '2013Mar22-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2014, April 04',
    filename: '2014Apr04-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2015, April 10',
    filename: '2015Apr10-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2016, April 15',
    filename: '2016Apr15-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2017, March 10',
    filename: '2017Mar10-ALAIR_BusMtg_Approved.pdf',
  },
  {
    label: '2018, April 13',
    filename: '2018Apr13-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2019, March 8',
    filename: '2019Mar8-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2020, March 13',
    filename: '2020Mar13-ALAIR_AnnualBusMtg.pdf',
  },
  {
    label: '2021, July 29',
    filename: '2021Jul29-ALAIR_AnnualBusMTG.pdf',
  },
  {
    label: '2022, March 25 for Approval at the 2023 Annual Business Meeting',
    filename: '2022Mar25-ALAIR_AnnualBusMTG for Approval.pdf',
  },
  {
    label: '2023, March 10',
    filename: '2023_10_03-Annual Bus MTG.pdf',
  },
  {
    label: '2024, March 15',
    filename: '2024_15_03-Annual Bus MTG.pdf',
  },

].reverse()

const executiveCommitteeMeetingMinutes = [
  {
    label: '2013, February 8th',
    filename: '2013Feb8-ALAIR_ExecBdMtg.pdf',
  },
  {
    label: '2013, March 21',
    filename: '2013Mar21-ALAIR_ExecBdMtg.pdf',
  },
  {
    label: '2013, May 31',
    filename: '2013May31-ALAIR_ExecBdTransitionalMtg.pdf',
  },
  {
    label: '2013, September 13',
    filename: '2013Sep13-ALAIR_ExecBdMtg.pdf',
  },
  {
    label: '2014, June 9',
    filename: '2014Jun9-ALAIR_ExecBdTransitionalMtg.pdf',
  },
].reverse()

// The above reverse

class Minutes extends React.Component {
  render() {
    return (
      <Layout pad>
        <h2>Meeting Minutes</h2>
        <hr />
        <h3>Annual Business Meeting Minutes</h3>
        <ul>
          {annualBusinessMeetingMinutes.map(link => (
            <li key={link.label}>
              <a href={`/minutes/${link.filename}`}>{link.label}</a>
            </li>
          ))}
        </ul>
        <hr />

        <h3>Executive Committee Meeting Minutes</h3>
        <ul>
          {executiveCommitteeMeetingMinutes.map(link => (
            <li key={link.label}>
              <a href={`/minutes/${link.filename}`}>{link.label}</a>
            </li>
          ))}
        </ul>
        <hr />
      </Layout>
    )
  }
}
export default Minutes
